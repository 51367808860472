import React from "react";

// gatsby
import { graphql } from "gatsby";

// @material-ui
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import MainLayout from "../components/layout/MainLayout";
import { SpecificTheme } from "../theme/default";
import { BlogPageQuery } from "./__generated__/BlogPageQuery";
import { get } from "../helpers/data";
import { PageProps } from "../typings";
import { Container } from "../components/general";
import Footer from "../components/general/Footer";
import SEO from "../components/SEO";
import BackgroundBlog from "../components/blog/BackgroundBlog";
import BlogPreview from "../components/blog/BlogPreview";
import { containerStyles } from "../components/general/Container";

const style = (theme: SpecificTheme) => {
  const paddingStyles = containerStyles(theme).padding;
  return createStyles({
    blogSite: {
      // Standard padding sizes
      ...paddingStyles,
      [theme.breakpoints.down("sm")]: {
        minHeight: "85vh"
      },
      [theme.breakpoints.up("md")]: {
        minHeight: "82vh",
        paddingTop: "30px"
      }
    }
  });
};

type BlogProps = WithStyles<typeof style> & PageProps<BlogPageQuery>;

const Blog: React.FC<BlogProps> = ({ classes, data, location }) => {
  const blogHeaderData = get(data, "blogHeaderImage", "fixed");
  const blogPreview = get(data, "allContentfulBlogPost", "edges");

  if (blogHeaderData) {
    return (
      <MainLayout location={location} title={"Blog Beiträge"}>
        <Container variant="standard" color="transparent">
          <BackgroundBlog>
            <Grid container spacing={0} className={classes.blogSite}>
              {blogPreview &&
                blogPreview.map(blogPreviewData => (
                  <BlogPreview
                    key={blogPreviewData.node.id}
                    blog={blogPreviewData.node}
                  />
                ))}
            </Grid>
          </BackgroundBlog>
        </Container>
        <Footer />
        <SEO
          general={{
            language: "de, en",
            contentType: "website",
            path: location.pathname,
            description: data.contentfulPages.seoDescription,
            title: data.contentfulPages.seoTitle
          }}
        />
      </MainLayout>
    );
  } else {
    return null;
  }
};

export default withStyles(style)(Blog);

export const pageQuery = graphql`
         query BlogPageQuery {
           blogHeaderImage: imageSharp(
             original: { src: { regex: "/blogLocal/" } }
           ) {
             fixed(width: 1000, traceSVG: { color: "lightgray" }) {
               ...GatsbyImageSharpFixed_tracedSVG
             }
           }
           contentfulPages(page: { eq: "Blog" }) {
             seoTitle
             seoDescription
           }
           allContentfulBlogPost(
             sort: { fields: [publishDate], order: DESC }
             filter: {
               publishingBlog: {
                 in: ["Twteam", "TwteamAndSimpleen", "SimpleenAndTwteam"]
               }
             }
           ) {
             edges {
               node {
                 id
                 title
                 slug
                 language
                 germanPublishDate: publishDate(formatString: "DD.MM.YYYY")
                 englishPublishDate: publishDate(formatString: "YYYY-MM-DD")
                 publishingBlog
                 previewText {
                   childMarkdownRemark {
                     html
                   }
                 }
                 previewImage {
                   id
                   title
                   description
                   fluid {
                     ...ContentfulFluidImage
                   }
                 }
               }
             }
           }
           ...logoFragment
         }
       `;
