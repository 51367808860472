import React from "react";
import { compose } from "recompose";
import {
  Button,
  Grid,
  WithStyles,
  withStyles,
  createStyles,
  Typography
} from "@material-ui/core";
import { BlogPageQuery_allContentfulBlogPost_edges_node } from "../../pages/__generated__/BlogPageQuery";
import { SpecificTheme } from "../../theme/default";
import { Image } from "../image/Image";
import { Link as GatsbyLink } from "gatsby";
import Event from "@material-ui/icons/Event";
import Comment from "@material-ui/icons/Comment";

type BlogPreviewProps = {
  blog: BlogPageQuery_allContentfulBlogPost_edges_node;
};

type InnerProps = BlogPreviewProps & WithStyles<typeof styles>;

const BlogPreview: React.FC<InnerProps> = ({ blog, classes }) => {
  return (
    <Grid container alignContent="stretch" className={classes.blogContainer}>
      <Grid item>
        <GatsbyLink to={`/blog/${blog.slug}`}>
          <Image image={blog.previewImage} className={classes.blogImage} />
          {/*
          <GatsbyImage
            fluid={blog.previewImage.fluid}
            className={classes.blogImage}
          />
          */}
        </GatsbyLink>
      </Grid>
      <Grid item className={classes.blogContent}>
        <GatsbyLink
          to={`/blog/${blog.slug}`}
          style={{ textDecoration: "none" }}
        >
          <Typography variant="h2" className={classes.blogTitle}>
            {blog.title}
          </Typography>
          <Typography variant="subtitle1" className={classes.blogSubTitle}>
            <Event
              style={{ fontSize: "1.45rem", verticalAlign: "text-bottom" }}
            />
            {blog.language === "de"
              ? blog.germanPublishDate
              : blog.englishPublishDate}

            <Comment
              style={{
                verticalAlign: "text-bottom",
                paddingLeft: "0.5rem"
              }}
            />
            {blog.language === "de" ? "Deutsch" : "English"}
          </Typography>
        </GatsbyLink>
        <div
          className={classes.blogDescription}
          dangerouslySetInnerHTML={{
            __html: blog.previewText.childMarkdownRemark.html
          }}
        />
        <GatsbyLink
          to={`/blog/${blog.slug}`}
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="contained"
            color="primary"
            className={classes.blogLink}
          >
            {blog.language === "de" ? "Weiterlesen" : "Continue reading"}
          </Button>
        </GatsbyLink>
      </Grid>
    </Grid>
  );
};

const styles = (theme: SpecificTheme) =>
  createStyles({
    blogContainer: {
      backgroundColor: "#fff",
      marginTop: "1rem",
      marginBottom: "1rem",
      height: "330px",
      [theme.breakpoints.down("sm")]: {
        width: "93vw",
        height: "100%",
        margin: "1rem auto"
      }
    },
    blogContent: {
      minHeight: "100%",
      [theme.breakpoints.down("sm")]: {
        padding: "0 10px 10px 10px"
      }
    },
    blogImage: {
      height: "330px",

      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        width: "93vw"
      },
      [theme.breakpoints.up("md")]: {
        width: "30vw"
      },
      [theme.breakpoints.only("xl")]: {
        width: "20vw"
      }
    },
    blogTitle: {
      marginTop: "1rem",
      fontSize: "1.75rem",
      [theme.breakpoints.up("md")]: {
        width: "60vw",
        paddingLeft: "30px"
      },
      [theme.breakpoints.up("xl")]: {
        width: "50vw"
      },
      color: theme.palette.text.primary
    },
    blogSubTitle: {
      marginTop: "0",
      marginBottom: "0",
      fontSize: "1.25rem",
      [theme.breakpoints.up("md")]: {
        width: "60vw",
        paddingLeft: "30px"
      },
      [theme.breakpoints.up("xl")]: {
        width: "50vw"
      }
    },
    blogDescription: {
      [theme.breakpoints.up("md")]: {
        width: "60vw",
        paddingLeft: "30px"
      },
      [theme.breakpoints.up("xl")]: {
        width: "50vw"
      }
      // color: "#fff"
    },
    blogLink: {
      [theme.breakpoints.up("md")]: {
        marginLeft: "30px"
      },
      textDecoration: "none"
    }
  });

export default compose<InnerProps, BlogPreviewProps>(withStyles(styles))(
  BlogPreview
);
